<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="lg-section" id="message">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md"></ion-col>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2" style="justify-content: center">
                  <h2 class="text-center">¡Error!</h2>
                    <ion-icon :icon="closeCircleOutline" color="danger" class="main-icon"/>
                  <p class="text-center">Algo salió mal... Hubo algun problema al generar tu pedido. Por favor intenteló de nuevo o contacte con nosotros.</p>
                  <ion-button shape="round" color="primary" @click="$router.push({ path: `/contact` })" class="contact-button" expand="block">
                    <ion-icon :icon="mailOutline" slot="start"/>
                    <ion-label>Contactar</ion-label>
                  </ion-button>
                  <ion-button shape="round" color="dark" fill="outline" @click="$router.push({ path: `/` })" class="return-button" expand="block">
                    <ion-icon :icon="arrowBackOutline" slot="start"/>
                    <ion-label>Volver a la Tienda</ion-label>
                  </ion-button>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md"></ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { closeCircleOutline, arrowBackOutline, mailOutline } from 'ionicons/icons';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'CheckoutSuccess',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    IonIcon
  },
  data () {
      return {
        order: {}
      }
  },
  methods: {
    ...mapActions(['getOrderByID', 'updateOrder']),
  },
  beforeMount(){
    this.getOrderByID(this.$route.params.id).then(res => {
      this.order = res.data();
      this.order.payment.status = 'Cancelled';
      this.order.status = 5;

      this.updateOrder({data: this.order, id: this.$route.params.id})
    });
  },
  setup() {
    return {
      closeCircleOutline, arrowBackOutline, mailOutline
    }
  }
});
</script>

<style scoped>

  .main-icon{
    width: 128px;
    height: 128px;
    margin: 1rem auto;
    display: block;
  }

  .return-button{
    margin-top: 1rem;
  }

  .contact-button{
    margin-top: 1.5rem;
  }
</style>